.container {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 1px;
}

.cards {
  height: 60vh;
  position: relative;
  display: grid;
  justify-content: center;
  grid-template-columns: 50% 50%;
}

.title_container {
  display: flex;
  align-items: center;
  padding: 3rem;
}

.title {
  margin: auto;
}

.title h1 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.title h2 {
  opacity: 77%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.title button {
  border: none;
  background-color: transparent;
}

.title button {
  text-align: center;
  display: inline-block;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 20px;
  border: none;
  position: relative;
  overflow: hidden;
  color: #bb86fc;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

.title button:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  background: #bb86fc;
  left: 0;
  bottom: 0;
  transition: all 0.3s;
  opacity: 0.3;
}

.title button:hover:before {
  width: 100%;
}

.skill {
  margin: auto;
}
.skill h1 {
  opacity: 60%;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  width: 20rem;
  border-bottom: 1px solid #ffffff;
  border-top: 1px solid #ffffff;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.skill h2 {
  opacity: 60%;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  color: #ffffff;
  width: 20rem;
  border-bottom: 1px solid #ffffff;
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.imgbox {
  height: 80vh;
  width: 100%;
  position: relative;
  margin-bottom: 4rem;
}

.title_img {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* content */
.content_cards {
  height: 100vh;
  display: grid;
  justify-content: center;
  grid-template-columns: 50% 50%;
  padding: 3rem;
}

.content_container {
  width: 100%;
  height: 100%;
  position: relative;
}

.box {
  width: 308px;
  height: 628px;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.7;
  background: #0057e3;
}

.overlay {
  width: 308px;
  height: 628px;
  position: absolute;
  top: 2rem;
  left: 8rem;
  z-index: 9;
  margin: 30px;
  background: #009938;
}

.content_title {
  margin-top: 4rem;
}

.content_title h1 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 700;
  line-height: normal;
}

.content_title h2 {
  opacity: 77%;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.content_container1 {
  width: 100%;
  height: 100%;
  position: relative;
}

.box1 {
  width: 308px;
  height: 628px;
  position: absolute;
  top: 0;
  right: 1rem;
  opacity: 0.7;
  background: #0057e3;
}

.overlay1 {
  width: 308px;
  height: 628px;
  position: absolute;
  top: 2rem;
  right: 9rem;
  z-index: 9;
  margin: 30px;
  background: #009938;
}
