.container {
  height: 100vh;
}

.cards {
  position: relative;
  height: 100vh;
  display: grid;
  justify-content: center;
  grid-template-columns: 50% 50%;
}

/* content */
.title_container {
  padding-left: 1rem;
  display: flex;
  align-items: center;
}

.title_row {
  display: flex;
  flex-direction: row;
}

.title_row h1 {
  color: #ffffff;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  font-size: 24px;
  font-weight: 700;
  line-height: normal;
}

.icon {
  color: #ffffff;
  background-color: transparent;
  margin-top: 1rem;
  text-shadow: 0 0 7px #fff, 0 0 10px #cf6679, 0 0 21px #cf6679,
    0 0 42px #cf6679, 0 0 82px #cf6679, 0 0 92px #cf6679, 0 0 102px #cf6679,
    0 0 151px #cf6679;
}

.content_row {
  margin-left: 3rem;
  margin-right: 3rem;
}

.content_row h3 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  opacity: 60%;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.content_row h2 {
  letter-spacing: 1px;
  margin-top: 1.8rem;
  opacity: 87%;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  line-height: 24px;
}

/* button */

.view_website:hover {
  box-shadow: 0 0 10px 0 #cf6679 inset, 0 0 10px 4px #cf6679;
}

.view_website {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #cf6679;
  border-radius: 0.6em;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;

  font-weight: 700;
  letter-spacing: 1px;
  margin-left: auto;
  margin-right: 4rem;
  margin-top: 4rem;
  border-color: #cf6679;
  color: #fff;
  box-shadow: 0 0 40px 40px #cf6679 inset, 0 0 0 0 #cf6679;
  transition: all 150ms ease-in-out;
}
