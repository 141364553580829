body {
  background-color: rgb(18, 18, 18);
  font-family: "Arial", sans-serif;
}

::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #252525;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #505050;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8f8f8f;
}
