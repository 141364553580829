.container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}

.title {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
}

.sub_title {
  color: #ffffff;
  font-size: 26px;
  font-weight: 400;
  line-height: normal;
}

.content_cards {
  display: grid;
  justify-content: center;
  grid-template-columns: 50% 50%;
  padding-top: 3rem;
  padding-bottom: 6rem;
}

.img_cards {
  display: grid;
  justify-content: center;
  grid-template-columns: 50% 50%;
}

.img_box {
  width: 205px;
  height: 418px;
}

.img_icon {
  margin-left: auto;
  padding-right: 4rem;
  width: 268px;
  height: 268px;
}

.button_img {
  width: 144px;
  height: 42px;
  cursor: pointer;
  filter: drop-shadow(6px 6px 4px #bb86fc);
  margin-left: 3rem;
}

.appstore {
  display: flex;
  padding-top: 4rem;
  flex-direction: row;
  align-items: center;
}

.arrowbutton {
  display: flex;
  color: #ffffff;
  background-color: #bb86fc;
  padding: 10px 16px;
  border-radius: 20px;
  transition: all 0.3s ease;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  font-size: 14px;
}

.arrowbutton > .arrow {
  width: 6px;
  height: 6px;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  position: relative;
  transform: rotate(-45deg);
  margin: 0 6px;
  transition: all 0.3s ease;
}

.arrowbutton > .arrow::before {
  display: block;
  background-color: currentColor;
  width: 3px;
  transform-origin: bottom right;
  height: 0px;
  position: absolute;
  opacity: 0;
  transition: all 0.3s ease;
  content: "";
  right: 0;
}

.arrowbutton:hover > .arrow {
  transform: rotate(-45deg) translate(4px, 4px);
  border-color: text-hover-color;
}

.arrowbutton:hover > .arrow::before {
  opacity: 1;
}

.arrowbutton:hover {
  background-color: #9368c8;
  color: #ffffff;
}
