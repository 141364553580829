.container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}

.title {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
}

.title2 {
  color: #ffffff;
  font-size: 16px;
  font-weight: 200;
  line-height: normal;
}

.sub_title {
  color: #ffffff;
  font-size: 20px;
  font-weight: 200;
  line-height: normal;
}

.content_cards {
  display: grid;
  justify-content: center;
  grid-template-columns: 50% 50%;
  padding-top: 3rem;
  padding-bottom: 6rem;
}

.img_cards {
  display: grid;
  justify-content: center;
  grid-template-columns: 50% 50%;
}

.img_box {
  width: 205px;
  height: 418px;
}

.img_icon {
  margin-left: auto;
  padding-right: 6rem;
  width: 268px;
  height: 268px;
}

.button_img {
  width: 174px;
  height: 54px;
  cursor: pointer;
  filter: drop-shadow(8px 8px 6px #bb86fc);
  margin-right: 3rem;
}

.appstore {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3rem;
}
