.container {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}

.content_cards {
  display: grid;
  justify-content: center;
  grid-template-columns: 50% 50%;
  padding-top: 3rem;
  gap: 3rem;
}

.title h1 {
  color: #ffffff;
  font-size: 34px;
  font-weight: 700;
  line-height: normal;
}

.title h2 {
  opacity: 77%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: #ffffff;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.content_bar {
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto auto;
  gap: 2rem;
}

.content_skills {
  margin-top: 10rem;
  text-align: center;
  padding: 1rem;
  box-shadow: 0 0 10px 0 #d9bbfc inset, 0 0 10px 4px #d9bbfc;
}

.content_skills h1 {
  color: #ffffff;
  font-size: 34px;
  font-weight: 700;
  line-height: normal;
}
