.container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 3rem;
  color: #ffffff;
}

.title {
  color: #ffffff;
  font-size: 30px;
  font-weight: 700;
  line-height: normal;
}
