.container {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

/*********************************** Responsive ************************************/
@media (min-width: 500px) {
  .container {
    width: 80%;
  }
}

@media (min-width: 768px) {
  .container {
    width: 80%;
  }
}

@media (min-width: 992px) {
  .container {
    width: 70%;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 70%;
  }
}
