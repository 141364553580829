.container {
  width: 60%;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
}

.text {
  margin: auto;
  position: relative;
  width: 100%;
  height: 100vh;
}

.text_container {
  position: absolute;
  top: 28%;
  left: 20%;
}

.text_container h1 {
  font-size: 2rem;
  color: #ffffff;
}

.title_one_row {
  display: flex;
  flex-direction: row;
}

.title_one_row h2 {
  font-size: 5rem;
  color: #ffffff;
}

.title1 {
  font-size: 5rem;
  color: #ffffff;
  margin-top: 2.4rem;
  margin-left: 2rem;
}

/*********************************** Responsive ************************************/
@media (min-width: 500px) {
  .container {
    width: 80%;
  }
  .text_container h1 {
    font-size: 1rem;
  }
  .title_one_row h2 {
    font-size: 1.5rem;
  }
  .title1 {
    font-size: 1.5rem;
    margin-top: 1.2rem;
  }
}

@media (min-width: 768px) {
  .container {
    width: 80%;
  }
  .text_container h1 {
    font-size: 1rem;
  }
  .title_one_row h2 {
    font-size: 2rem;
  }
  .title1 {
    font-size: 2rem;
    margin-top: 1.5rem;
  }
}

@media (min-width: 992px) {
  .container {
    width: 60%;
  }
  .text_container h1 {
    font-size: 2rem;
  }
  .title_one_row h2 {
    font-size: 3rem;
  }
  .title1 {
    font-size: 3rem;
    margin-top: 2.4rem;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 60%;
  }

  .text_container h1 {
    font-size: 2rem;
  }
  .title_one_row h2 {
    font-size: 3rem;
  }
  .title1 {
    font-size: 3rem;
    margin-top: 2.4rem;
  }
}
