.contact {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}

.title {
  padding-top: 3rem;
  text-align: center;
  color: #ffffff;
  font-size: 34px;
  font-weight: 700;
  line-height: normal;
}

.message {
  text-align: center;
  color: #bb86fc;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

form {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

input,
textarea {
  width: 100%;
  padding: 1.5rem;
  border-radius: 0.5rem;
  background: transparent;
  border: 2px solid #ffffff;
  resize: none;
  color: #ffffff;
}

.button {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid #bb86fc;
  border-radius: 0.6em;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;

  font-weight: 700;
  letter-spacing: 1px;
  border-color: #bb86fc;
  color: #fff;
  box-shadow: 0 0 40px 40px #bb86fc inset, 0 0 0 0 #bb86fc;
  transition: all 150ms ease-in-out;
}

.button:hover {
  box-shadow: 0 0 10px 0 #bb86fc inset, 0 0 10px 4px #bb86fc;
}
