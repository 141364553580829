.navbar {
  width: 100vh;
  height: 1vh;
  position: fixed;
  transform-origin: left top;
  transform: rotate(-90deg) translateX(-100%);
}

.navMenu {
  display: flex;
  justify-content: space-between;
  margin-right: 3rem;
  height: 180px;
}

.navMenu li {
  list-style: none;
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
}

.navMenu a {
  display: block;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  text-decoration: none;
}

.navMenu a:hover {
  color: #ffffff;
}

.navMenu a.active {
  color: #bb86fc;
}

span {
  position: relative;
  display: block;
}

span:before,
span:after {
  content: "";
  position: absolute;
  width: 0%;
  height: 4px;
  top: 50%;
  margin-top: -0.5px;
  background: #bb86fc;
}

span:before {
  left: -2.5px;
}
span:after {
  right: 2.5px;
  background: #bb86fc;
  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
}

span:hover:before {
  background: #bb86fc;
  width: 100%;
  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

span:hover:after {
  background: transparent;
  width: 100%;
  transition: 0s;
}

.logos {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding-top: 1rem;
}

.logo {
  width: 30px;
  height: 30px;
  transform: rotate(90deg);
}

.logos a:hover {
  color: #bb86fc;
}

/* homepage */

.content {
  position: relative;
  transform: rotate(90deg);
  margin-left: 3rem;
}

.content h2 {
  color: #ffffff;
  font-size: 2em;
  position: absolute;
  transform: translate(-50%, -50%);
}

.content h2:nth-child(1) {
  color: transparent;
  -webkit-text-stroke: 2px #bb86fc;
}

.content h2:nth-child(2) {
  color: #bb86fc;
  animation: animate 4s ease-in-out infinite;
}

@keyframes animate {
  0%,
  100% {
    clip-path: polygon(
      0% 45%,
      16% 44%,
      33% 50%,
      54% 60%,
      70% 61%,
      84% 59%,
      100% 52%,
      100% 100%,
      0% 100%
    );
  }

  50% {
    clip-path: polygon(
      0% 60%,
      15% 65%,
      34% 66%,
      51% 62%,
      67% 50%,
      84% 45%,
      100% 46%,
      100% 100%,
      0% 100%
    );
  }
}

/*********************************** Responsive ************************************/
@media (min-width: 500px) {
  .navMenu {
    height: 45px;
  }
}

@media (min-width: 768px) {
  .navMenu {
    height: 45px;
  }
}

@media (min-width: 992px) {
  .navMenu {
    height: 180px;
  }
}

@media (min-width: 1200px) {
  .navMenu {
    height: 180px;
  }
}
